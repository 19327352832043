import type { FC } from 'react';

import type { Product } from 'productSelection/types/products';
import type { IUiCartFields } from 'shared/infra/contentful/contentful';
import { getConditionProducts } from 'shared/services/ProductService';

import { OverviewWrapper, StyledHeadline } from '../../OrderOverview.styles';
import type { OverviewProps } from '../../OrderOverviewService';
import { ConditionRow } from '../ConditionRow/ConditionRow';

type ConditionsContent = Pick<IUiCartFields, 'conditionsTitleText'>;

export interface Props extends OverviewProps, ConditionsContent {
  products: Product[];
}

export const ConditionsCard: FC<Props> = (props) => {
  const { conditionsTitleText, noMargin, products, usedForCart, usedInModal } =
    props;

  const conditions = getConditionProducts(products);

  return conditions.length > 0 ? (
    <OverviewWrapper noMargin={noMargin} usedInModal={usedInModal}>
      <StyledHeadline as="h2" size="m">
        {conditionsTitleText}
      </StyledHeadline>
      <ul>
        {conditions.map((condition) => (
          <ConditionRow
            condition={condition}
            key={condition.trackingId}
            usedForCart={usedForCart}
          />
        ))}
      </ul>
    </OverviewWrapper>
  ) : null;
};
