import type { FC } from 'react';

import type { Product } from 'productSelection/types/products';
import { PaymentPlanCard } from 'shared/components/OrderOverview/components/PaymentPlanCard/PaymentPlanCard';
import { usePaymentPlan } from 'shared/hooks/orders/usePaymentPlan';
import type { IPlan, IUiCartFields } from 'shared/infra/contentful/contentful';
import type { SkuCodePurchaseLimit } from 'shared/infra/storefront/markets/prices';
import { isQuotesChannel } from 'shared/services/channel';
import { isFlagEnabledInBrowser, FlagConfig } from 'shared/services/optimizely';
import type { OrderSubscription } from 'shared/services/ProductService';
import { useTypedSelector } from 'shared/store';
import type { OrderDetails as IOrderDetails } from 'shared/store/order/types';
import { getChannel } from 'shared/utils/channel-link';
import type { Maybe } from 'types/util';

import { ConditionsCard } from './components/ConditionsCard/ConditionsCard';
import { DueMonthlyCard } from './components/DueMonthlyCard/DueMonthlyCard';
import { DueNowCard } from './components/DueNowCard/DueNowCard';
import { PlanCard } from './components/PlanCard/PlanCard';
import { YourOrderCard } from './components/YourOrderCard/YourOrderCard';

type OrderDetails = Pick<
  IOrderDetails,
  | 'couponCode'
  | 'discountAmountCents'
  | 'formattedDiscountAmount'
  | 'formattedSubtotalAmount'
  | 'formattedTotalAmountWithTaxes'
  | 'formattedTotalTaxAmount'
  | 'id'
  | 'taxRate'
>;

export type OverviewContent = Pick<
  IUiCartFields,
  | 'businessAccountDescription'
  | 'businessAccountCostsLabel'
  | 'conditionsTitleText'
  | 'couponCodeRemoveButtonLabel'
  | 'couponDiscountAppliedLabel'
  | 'deliveryEstimateText'
  | 'discountAppliedText'
  | 'dueMonthlyTitleText'
  | 'dueMonthlyTotalText'
  | 'dueMonthlyPaymentStartLabel'
  | 'dueNowTitleText'
  | 'freeText'
  | 'overviewTitleText'
  | 'quantityText'
  | 'subtotalText'
  | 'taxRateText'
  | 'totalText'
  | 'paymentScheduleTitleText'
  | 'paymentScheduleFirstInstallmentText'
  | 'paymentScheduleNextInstallmentsText'
  | 'paymentScheduleNextInstallmentsAdditionalInfoText'
  | 'paymentScheduleFeesText'
  | 'defaultPlan'
>;

export interface Props extends OverviewContent, Partial<OrderDetails> {
  products?: Product[];
  usedInModal?: boolean;
  loading?: boolean;
  usedForCart?: boolean;
  isQuantitySelectionEnabled?: boolean;
  largestInstallmentInCart?: number;
  installmentsOnCheckout?: number;
  installments?: number;
  totalAmountFloat?: number;
  totalAmountWithTaxesFloat?: number;
  noMargin: boolean;
  imgHydration?: 'lazy' | 'eager';
  onLineItemQuantityChange?: (product: Product) => (quantity: number) => void;
  hasBusinessAccount?: boolean;
  defaultTaxRate: number;
  purchaseLimitsBySkuCodes?: SkuCodePurchaseLimit[];
  hideProducts?: boolean;
  showCouponForm?: boolean;
  isLogoClickable: boolean;
  orderId: string;
  hasSumUpOne?: boolean;
  hasOnlySumUpOne?: boolean;
  orderSubscription: Maybe<OrderSubscription>;
  isWebsitePDPExperimentEnabled?: boolean;
  defaultPlan?: IPlan;
}

/**
 * The OrderOverview is the component responsible to present
 * an overview from user's order. It is mainly used inside
 * Cart component and Signup/Login Page.
 */
export const OrderOverview: FC<Props> = (props: Props) => {
  const {
    conditionsTitleText,
    defaultTaxRate,
    deliveryEstimateText,
    discountAppliedText,
    dueMonthlyTitleText,
    dueMonthlyTotalText,
    dueMonthlyPaymentStartLabel,
    dueNowTitleText,
    freeText,
    paymentScheduleTitleText,
    paymentScheduleFirstInstallmentText,
    paymentScheduleNextInstallmentsText,
    paymentScheduleNextInstallmentsAdditionalInfoText,
    paymentScheduleFeesText,
    discountAmountCents,
    formattedDiscountAmount,
    formattedSubtotalAmount = '',
    formattedTotalAmountWithTaxes,
    formattedTotalTaxAmount = '',
    hasBusinessAccount,
    noMargin,
    products,
    usedInModal,
    taxRate = 0,
    taxRateText,
    totalAmountFloat,
    subtotalText,
    hasSumUpOne = false,
    hasOnlySumUpOne = false,
    isWebsitePDPExperimentEnabled = false,
    orderSubscription,
    defaultPlan,
  } = props;

  const shippingAddress = useTypedSelector((s) => s.addresses.shippingAddress);
  const channel = getChannel();
  const orderState = useTypedSelector((state) => state.order);
  const paymentPlanState = usePaymentPlan(orderState.orderDetails);

  const orderDetails = {
    discountAmountCents,
    formattedDiscountAmount,
    formattedSubtotalAmount,
    formattedTotalAmountWithTaxes,
    formattedTotalTaxAmount,
    taxRate,
  };

  const dueNowContent = {
    deliveryEstimateText,
    discountAppliedText,
    dueNowTitleText,
    freeText,
    subtotalText,
    taxRateText,
  };

  const dueMonthlyContent = {
    dueMonthlyTitleText,
    dueMonthlyTotalText,
    dueMonthlyPaymentStartLabel,
    subtotalText,
    discountAppliedText,
    taxRateText,
  };

  const paymentPlanContent = {
    freeText,
    paymentScheduleTitleText,
    paymentScheduleFirstInstallmentText,
    paymentScheduleNextInstallmentsText,
    paymentScheduleNextInstallmentsAdditionalInfoText,
    paymentScheduleFeesText,
  };

  const isWebsiteChannel = channel === 'website';

  const hasWebsiteConfiguratorEnabled =
    isWebsitePDPExperimentEnabled ||
    isFlagEnabledInBrowser(FlagConfig.WEBSITE_PDP_CONFIGURATOR);

  return isQuotesChannel(channel) ? (
    <article>
      <DueNowCard
        content={dueNowContent}
        orderDetails={orderDetails}
        defaultTaxRate={defaultTaxRate}
        hasBusinessAccount={hasBusinessAccount}
        noMargin={noMargin}
        products={products}
        shippingAddress={shippingAddress}
        totalAmountFloat={totalAmountFloat}
        usedInModal={usedInModal}
      />
      <PaymentPlanCard
        content={paymentPlanContent}
        noMargin={noMargin}
        paymentPlanState={paymentPlanState}
        usedInModal={usedInModal}
      />
      <DueMonthlyCard
        content={dueMonthlyContent}
        noMargin={noMargin}
        products={products}
        orderSubscription={orderSubscription}
        usedInModal={usedInModal}
      />
      <ConditionsCard
        conditionsTitleText={conditionsTitleText}
        noMargin={noMargin}
        products={products}
        usedInModal={usedInModal}
      />
    </article>
  ) : (
    <>
      {!hasOnlySumUpOne && <YourOrderCard {...props} />}

      {isWebsiteChannel && hasWebsiteConfiguratorEnabled && (
        <PlanCard
          products={products}
          dueMonthlyContent={dueMonthlyContent}
          defaultTaxRate={defaultTaxRate}
          usedInModal={usedInModal}
          hasSumUpOne={hasSumUpOne}
          defaultPlan={defaultPlan}
        />
      )}
    </>
  );
};
