import { Body } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

import * as TestIds from 'shared/constants/TestIds';

import { DescriptionContainer } from '../../OrderOverview.styles';

export type Props = {
  amountFormatted: string;
  appliedText: string;
  usedForCart: boolean;
};

export const DiscountRow: FC<Props> = (props) => {
  const { appliedText, amountFormatted, usedForCart } = props;

  return (
    <DescriptionContainer
      data-testid={TestIds.OrderOverview.DiscountWrapper}
      usedForCart={usedForCart}
    >
      <Body>{appliedText}</Body>
      <Body>{amountFormatted}</Body>
    </DescriptionContainer>
  );
};
