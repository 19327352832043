import {
  createInstance,
  OptimizelyDecideOption,
  LogLevel as OptimizelyLogLevel,
} from '@optimizely/optimizely-sdk';
import type { Client as OptimizelyClient } from '@optimizely/optimizely-sdk/dist/optimizely.lite.min';
import type { NextFetchEvent } from 'next/server';

import type { Maybe } from 'types/util';

import logger from '../logger';

import { getDatafile } from './data-file';
import { createEventDispatcher } from './edge-event-dispatcher';

export async function getOptimizelyEdgeClient(
  eventDispatcher: NextFetchEvent,
): Promise<Maybe<OptimizelyClient>> {
  const datafile = await getDatafile();

  const client = createInstance({
    datafile,
    clientEngine: 'javascript-sdk/vercel-edge',
    defaultDecideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
    eventDispatcher: createEventDispatcher(eventDispatcher),
    logLevel:
      process.env.NODE_ENV === 'development'
        ? OptimizelyLogLevel.WARNING
        : OptimizelyLogLevel.ERROR,
  });

  if (!client) {
    logger.error('Failed to create Optimizely client');
  }

  return client;
}
