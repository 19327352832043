import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import { Body, Headline, Hr, List } from '@sumup-oss/circuit-ui';
import type { ReactNode } from 'react';

import { ExternalLink, InternalLink } from 'shared/components/Link';
import { getChannelLink } from 'shared/utils/channel-link';

export const Italic = styled(Body)`
  font-style: italic;
`;

export const deliveryEstimateOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Body weight="semibold" as="strong">
        {text}
      </Body>
    ),
    [MARKS.ITALIC]: (text) => <Italic as="p">{text}</Italic>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node.data.uri as string;
      const isExternalLink = href.startsWith('http');

      return (
        <>
          {isExternalLink && (
            <ExternalLink href={href}>{children}</ExternalLink>
          )}
          {!isExternalLink && (
            <InternalLink href={getChannelLink(href)}>{children}</InternalLink>
          )}
        </>
      );
    },
    [BLOCKS.UL_LIST]: (_, children) => (
      <List css={{ marginBottom: 'var(--cui-spacings-byte)' }}>{children}</List>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <List
        variant="ordered"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </List>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Headline
        size="m"
        as="h2"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Headline
        size="s"
        as="h3"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Headline
        size="s"
        as="h4"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.QUOTE]: (_, children) => (
      <Body variant="quote" as="blockquote">
        {children}
      </Body>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => <span>{children}</span>,
  },
  renderText: (text: string) => {
    const reducer = (
      children: ReactNode[],
      textSegment: string,
      index: number,
    ): ReactNode[] => [
      ...children,
      index > 0 && <br key={index} />,
      textSegment,
    ];
    return text.split('\n').reduce<ReactNode[]>(reducer, []);
  },
};
