import { Body } from '@sumup-oss/circuit-ui';
import { Tooltip } from '@sumup-oss/circuit-ui/legacy';
import { useState, type FC } from 'react';

import type { Product } from 'productSelection/types/products';
import { isFeeCondition } from 'shared/services/ProductService';

import {
  DescriptionContainer,
  InfoIcon,
  PrintableFeeConditions,
  TooltipContainer,
} from '../../OrderOverview.styles';

export interface Props {
  condition: Product;
  usedForCart: boolean;
}

export const ConditionRow: FC<Props> = (props) => {
  const { condition, usedForCart } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = (): void => {
    setTooltipVisible(!tooltipVisible);
  };

  return isFeeCondition(condition) ? (
    <DescriptionContainer as="li" usedForCart={usedForCart}>
      <Body>
        {condition.name}
        <InfoIcon
          name="info"
          aria-label="info"
          size="16"
          onMouseEnter={toggleTooltip}
          onMouseLeave={toggleTooltip}
        />
        {tooltipVisible && (
          <TooltipContainer>
            <Tooltip align="top" position="right">
              {condition.productDescription}
            </Tooltip>
          </TooltipContainer>
        )}
        <PrintableFeeConditions>
          {condition.productDescription}
        </PrintableFeeConditions>
      </Body>
    </DescriptionContainer>
  ) : (
    <DescriptionContainer as="li" usedForCart={usedForCart}>
      <Body>{condition.name}</Body>
      <Body>{condition.productDescription}</Body>
    </DescriptionContainer>
  );
};
