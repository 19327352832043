import { Body } from '@sumup-oss/circuit-ui';
import { useState, type FC } from 'react';

import type { Product } from 'productSelection/types/products';
import type { IPlan, IPlanFields } from 'shared/infra/contentful/contentful';
import type {
  OrderSubscription,
  Subscription,
} from 'shared/services/ProductService';
import { SUMUP_ONE_SKU } from 'src/cart/services/CartOverviewService';

import {
  FeeRateContainer,
  InfoIcon,
  OverviewWrapper,
  PlanFeeContainer,
  StyledHeadline,
  StyleInvertedTooltip,
  TooltipContainer,
  TransactionTypes,
} from '../../OrderOverview.styles';
import {
  DueMonthlyCard,
  type DueMonthlyContent,
} from '../DueMonthlyCard/DueMonthlyCard';

type Props = {
  products?: Product[];
  dueMonthlyContent: DueMonthlyContent;
  defaultTaxRate: number;
  usedInModal?: boolean;
  hasSumUpOne?: boolean;
  defaultPlan?: IPlan;
};

function getPlanFields(
  products: Product[],
  hasSumUpOne: boolean,
  defaultPlan: IPlan,
): IPlanFields | undefined {
  const sumUpOneProduct = products?.find(
    (product) => product.code === SUMUP_ONE_SKU,
  );

  const planFields = hasSumUpOne
    ? sumUpOneProduct?.plan?.fields
    : defaultPlan?.fields;

  return planFields;
}

function mapSumUpOneProduct(
  product: Product,
  planFields: IPlanFields,
  defaultTaxRate: number,
): Product {
  const isSumUpOne = product.code === SUMUP_ONE_SKU;

  return isSumUpOne
    ? {
        ...product,
        subscription: {
          tax_amount_float: 0,
          tax_rate: defaultTaxRate,
          total_amount_float: planFields.monthlyCost,
          total_amount_with_taxes_and_discounts_float: planFields.monthlyCost,
        } as Subscription, // FIXME
      }
    : product;
}

function createSumUpOneOrderSubscription(
  planFields: IPlanFields,
  defaultTaxRate: number,
): OrderSubscription {
  return {
    tax_amount_float: 0,
    tax_rate: defaultTaxRate,
    total_amount_float: planFields.monthlyCost,
    total_amount_with_taxes_and_discounts_float: planFields.monthlyCost,
    trial_days: 7,
    subscriptions: [
      {
        quantity: 1,
        tax_amount_float: 0,
        tax_rate: defaultTaxRate,
        total_amount_float: planFields.monthlyCost,
        total_amount_with_taxes_and_discounts_float: planFields.monthlyCost,
      },
    ],
  } as OrderSubscription; // FIXME
}

export const PlanCard: FC<Props> = (props) => {
  const {
    products,
    dueMonthlyContent,
    defaultTaxRate,
    usedInModal,
    hasSumUpOne = false,
    defaultPlan,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleTooltip = (): void => {
    setTooltipVisible(!tooltipVisible);
  };

  const planFields = getPlanFields(products, hasSumUpOne, defaultPlan);

  if (!planFields) {
    return null;
  }

  const dueMonthlyProducts = products.map((product) =>
    mapSumUpOneProduct(product, planFields, defaultTaxRate),
  );

  const orderSubscription = createSumUpOneOrderSubscription(
    planFields,
    defaultTaxRate,
  );

  return (
    <>
      {hasSumUpOne && (
        <DueMonthlyCard
          content={dueMonthlyContent}
          noMargin
          products={dueMonthlyProducts}
          orderSubscription={orderSubscription}
          usedInModal={usedInModal}
        />
      )}
      <OverviewWrapper noMargin={!hasSumUpOne} usedInModal={usedInModal}>
        <StyledHeadline as="h2" size="m">
          {planFields.feesLabel}
        </StyledHeadline>
        <PlanFeeContainer>
          <FeeRateContainer>
            <Body>
              {planFields.inPersonFeesLabel}
              {planFields.premiumCardFeeNotice && (
                <>
                  <InfoIcon
                    name="info"
                    aria-label="info"
                    size="16"
                    onMouseEnter={toggleTooltip}
                    onMouseLeave={toggleTooltip}
                    usedInPlanCard
                  />
                  {tooltipVisible && (
                    <TooltipContainer alignedAtBottom>
                      <StyleInvertedTooltip align="bottom" position="bottom">
                        {planFields.premiumCardFeeNotice}
                      </StyleInvertedTooltip>
                    </TooltipContainer>
                  )}
                </>
              )}
            </Body>
            <Body>{planFields.inPersonFeeRate}%</Body>
          </FeeRateContainer>
          {planFields.inPersonTransactionTypes && (
            <TransactionTypes size="m">
              {planFields.inPersonTransactionTypes}
            </TransactionTypes>
          )}
        </PlanFeeContainer>
        <PlanFeeContainer>
          <FeeRateContainer>
            <Body>{planFields.digitalFeesLabel}</Body>
            <Body>{planFields.digitalFeeRate}%</Body>
          </FeeRateContainer>
          {planFields.digitalTransactionTypes && (
            <TransactionTypes size="m">
              {planFields.digitalTransactionTypes}
            </TransactionTypes>
          )}
        </PlanFeeContainer>
      </OverviewWrapper>
    </>
  );
};
