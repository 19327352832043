import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Body } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

import type { IUiCartFields } from 'shared/infra/contentful/contentful';

import {
  DescriptionContainer,
  FreeDeliveryText,
} from '../../OrderOverview.styles';
import { deliveryEstimateOptions } from '../YourOrderCard/components/deliveryEstimateOptions';

type BusinessAccountRowContent = Pick<
  IUiCartFields,
  'deliveryEstimateText' | 'freeText'
>;

export interface Props extends BusinessAccountRowContent {
  usedForCart: boolean;
}

export const DeliveryRow: FC<Props> = (props) => {
  const { deliveryEstimateText, freeText, usedForCart } = props;

  const richText = documentToReactComponents(
    deliveryEstimateText,
    deliveryEstimateOptions,
  );

  return deliveryEstimateText ? (
    <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
      <Body>{richText}</Body>
      <FreeDeliveryText>{freeText}</FreeDeliveryText>
    </DescriptionContainer>
  ) : null;
};
