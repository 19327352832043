import {
  OptimizelyDecideOption,
  type OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';
import { type NextRequest, NextResponse, userAgent } from 'next/server';

import { isBotTraffic } from 'shared/services/optimizely/bot-traffic';
import {
  type Experiment,
  getExperimentsFromDecision,
  getFlagsFromDecision,
} from 'shared/services/optimizely/decision-flags';
import { getOptimizelyEdgeClient } from 'shared/services/optimizely/edge-client';
import { getUserFromRequest } from 'shared/services/optimizely/edge-user';
import { getRunningFlagKeys } from 'shared/services/optimizely/flag-config';
import {
  getExperimentsFromURLQuery,
  getFlagsFromURLQuery,
} from 'shared/services/optimizely/preview-flags';

import type { MiddlewareFactory } from '../../../../middleware/types';

import {
  applySetCookie,
  cleanupExperimentCookiesInResponse,
  cleanupFlagCookiesInResponse,
  setExperimentCookieInResponse,
  setFlagCookieInResponse,
  setUserIdCookieInResponse,
} from './edge-cookies';

const setExperimentsInResponse = (
  experiments: Experiment[],
  request: NextRequest,
  response: NextResponse,
): void => {
  cleanupExperimentCookiesInResponse(
    experiments,
    request.cookies,
    response.cookies,
  );

  experiments.forEach((experiment) => {
    setExperimentCookieInResponse(
      response,
      experiment.experimentKey,
      experiment.variationKey,
    );
  });
};

const setFlagsInResponse = (
  flags: string[],
  request: NextRequest,
  response: NextResponse,
): void => {
  cleanupFlagCookiesInResponse(flags, request.cookies, response.cookies);

  flags.forEach((flag) => {
    setFlagCookieInResponse(response, flag, 'true');
  });
};

const setUserInResponse = (
  response: NextResponse,
  user: OptimizelyUserContext,
): void => {
  const id = user.getUserId();

  setUserIdCookieInResponse(response, id);
};

export const withActiveFlags: MiddlewareFactory =
  (next) => async (request, eventDispatcher) => {
    const response = await next(request, eventDispatcher);

    if (!response || !('cookies' in response)) {
      return response;
    }

    const { device } = userAgent(request);
    const { nextUrl } = request;

    nextUrl.searchParams.set('device', device.type || 'desktop');

    const runningFlagKeys = getRunningFlagKeys();

    const hasFlags = runningFlagKeys.length > 0;
    const shouldSkipMiddleware = !hasFlags || isBotTraffic(request);

    if (shouldSkipMiddleware) {
      return NextResponse.rewrite(nextUrl);
    }

    const optimizelyClient = await getOptimizelyEdgeClient(eventDispatcher);

    if (!optimizelyClient) {
      return NextResponse.rewrite(nextUrl);
    }

    await optimizelyClient.onReady();

    const user = getUserFromRequest(optimizelyClient, request);

    if (!user) {
      return NextResponse.rewrite(nextUrl);
    }

    setUserInResponse(response, user);

    // We disable the decision event inside the midleware.
    // We emit the event on client side.
    // Reason: The user needs to give active GDPR consent before we
    // emit any events to optimizely. This consent is given on the client
    // side.
    const decisions = user.decideForKeys(runningFlagKeys, [
      OptimizelyDecideOption.DISABLE_DECISION_EVENT,
      OptimizelyDecideOption.ENABLED_FLAGS_ONLY,
    ]);

    const previewFlags = getFlagsFromURLQuery(request);
    const decisionFlags = getFlagsFromDecision(decisions);

    setFlagsInResponse([...decisionFlags, ...previewFlags], request, response);

    const previewExperiments = getExperimentsFromURLQuery(request);
    const decisionExperiments = getExperimentsFromDecision(decisions);

    setExperimentsInResponse(
      [...decisionExperiments, ...previewExperiments],
      request,
      response,
    );

    applySetCookie(request, response);

    return response;
  };
