import type { UrlObject } from 'url';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Anchor, type AnchorProps } from '@sumup-oss/circuit-ui';
import NextLink, { type LinkProps } from 'next/link';
import { forwardRef, type FC, type LegacyRef } from 'react';

export interface InternalLinkProps
  extends Pick<
      LinkProps,
      | 'href'
      | 'replace'
      | 'scroll'
      | 'shallow'
      | 'passHref'
      | 'prefetch'
      | 'locale'
    >,
    Omit<AnchorProps, 'href' | 'ref'> {
  href: string | UrlObject;
  ref?: LegacyRef<any>;
}

export type ExternalLinkProps = Pick<
  typeof Anchor,
  'defaultProps'
>['defaultProps'];

type PatchedAnchorProps = Omit<AnchorProps, 'ref'> & {
  ref?: LegacyRef<any>;
};

const StyledLink = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
);

const StyledExternalLink = styled(StyledLink)`
  color: var(--cui-fg-accent);
  font-weight: initial;
  text-decoration: underline;
`;

const LinkWrapper = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  AnchorProps
>(({ children, ...props }, ref) => (
  <StyledLink {...props} ref={ref}>
    {children}
  </StyledLink>
));

export const InternalLink: FC<InternalLinkProps> = ({
  href,
  as = 'a',
  replace,
  scroll,
  shallow,
  passHref = true,
  prefetch,
  locale,
  children,
  ...props
}) => (
  <NextLink
    href={href}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    passHref={passHref}
    prefetch={prefetch}
    locale={locale}
    legacyBehavior
  >
    <LinkWrapper variant="highlight" {...props} as={as}>
      {children}
    </LinkWrapper>
  </NextLink>
);

export const ExternalLink: FC<PatchedAnchorProps> = ({
  children,
  ...props
}) => (
  <StyledExternalLink {...props} variant="highlight">
    {children}
  </StyledExternalLink>
);
