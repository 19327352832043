import { Body } from '@sumup-oss/circuit-ui';

import type { AddressPayload } from 'shared/store/address/types';
import { getFormattedTaxAmount, getPercentageTaxRate } from 'utils/tax';

import { DescriptionContainer } from '../../YourOrderCard.styles';

export interface VatRateLineProps {
  defaultTaxRate: number;
  doFallbackToDefaultTaxRate: boolean;
  formattedTotalTaxAmount: string;
  locale: string;
  shippingAddress: AddressPayload;
  taxRate: number;
  taxRateText: string;
  totalAmountFloat: number;
  usedForCart: boolean;
}

export const VatRateLine = ({
  shippingAddress,
  taxRate,
  defaultTaxRate,
  usedForCart,
  taxRateText,
  formattedTotalTaxAmount,
  totalAmountFloat,
  locale,
  doFallbackToDefaultTaxRate,
}: VatRateLineProps) => {
  // If address is attached, use the order tax rate calculated by CommerceLayer.
  if (taxRate > 0) {
    return (
      <DescriptionContainer usedForCart={usedForCart}>
        <Body>{`${taxRateText} ${getPercentageTaxRate(taxRate)}%`}</Body>
        <span>{formattedTotalTaxAmount}</span>
      </DescriptionContainer>
    );
  }

  // If no address is attached, use the default tax rate to render vat.
  // Background: CommerceLayer is calculating the correct taxes for an order once an address is attached to the order.
  // We still want to show the default tax rate before this calculation happens.
  if (
    defaultTaxRate > 0 &&
    !shippingAddress?.id &&
    doFallbackToDefaultTaxRate
  ) {
    return (
      <DescriptionContainer usedForCart={usedForCart}>
        <Body>{`${taxRateText} ${getPercentageTaxRate(defaultTaxRate)}%`}</Body>
        <span>
          {getFormattedTaxAmount(defaultTaxRate, totalAmountFloat, locale)}
        </span>
      </DescriptionContainer>
    );
  }

  return null;
};
