import { useRouter } from 'next/router';
import type { FC } from 'react';

import type { OrderOverviewProps } from 'shared/components/OrderOverview';
import * as TestIds from 'shared/constants/TestIds';
import type { IUiCartFields } from 'shared/infra/contentful/contentful';
import {
  getLicenseProductType,
  type OrderSubscription,
} from 'shared/services/ProductService';
import { parseTranslation } from 'shared/utils/translation';
import type { Maybe } from 'types/util';
import { formatCurrencyWithLocale } from 'utils/currency';

import {
  OverviewWrapper,
  StyledHeadline,
  StyledHr,
} from '../../OrderOverview.styles';
import type { OverviewProps } from '../../OrderOverviewService';
import { DiscountRow } from '../DiscountRow/DiscountRow';
import { SubtotalRow } from '../SubtotalRow/SubtotalRow';
import { TotalAmountRow } from '../TotalAmountRow/TotalAmountRow';
import { VatRateRow } from '../VatRateRow/VatRateRow';

export type DueMonthlyContent = Pick<
  IUiCartFields,
  | 'discountAppliedText'
  | 'dueMonthlyTitleText'
  | 'dueMonthlyTotalText'
  | 'dueMonthlyPaymentStartLabel'
  | 'subtotalText'
  | 'taxRateText'
>;

export interface Props extends OverviewProps {
  content: DueMonthlyContent;
  products: OrderOverviewProps['products'];
  orderSubscription: Maybe<OrderSubscription>;
}

export const DueMonthlyCard: FC<Props> = (props) => {
  const {
    content,
    noMargin,
    products,
    usedForCart,
    usedInModal,
    orderSubscription,
  } = props;
  const { locale } = useRouter();

  if (!orderSubscription) {
    return null;
  }

  const subtotalAmountFormatted = formatCurrencyWithLocale(
    orderSubscription.total_amount_float,
    locale,
  );

  const licenseProductType = getLicenseProductType(products);

  const discountAmountFormatted = orderSubscription.discount_float
    ? formatCurrencyWithLocale(orderSubscription.discount_float, locale)
    : '';

  const totalTaxAmountFormatted = formatCurrencyWithLocale(
    orderSubscription.tax_amount_float,
    locale,
  );

  const totalAmountFormatted = formatCurrencyWithLocale(
    orderSubscription.total_amount_with_taxes_and_discounts_float,
    locale,
  );

  const dueMonthlyPaymentStartLabel =
    orderSubscription.trial_days && orderSubscription.trial_days > 0
      ? parseTranslation(content.dueMonthlyPaymentStartLabel || '', {
          trialDays: orderSubscription.trial_days.toString(),
        })
      : '';

  return (
    <OverviewWrapper
      css={{ marginBottom: 'var(--cui-spacings-giga)' }}
      data-testid={TestIds.OrderOverview.ContainerWrapper}
      noMargin={noMargin}
      usedInModal={usedInModal}
    >
      <StyledHeadline size="m" as="h2">
        {content.dueMonthlyTitleText}
      </StyledHeadline>

      <SubtotalRow
        amountFormatted={subtotalAmountFormatted}
        productTypes={[licenseProductType]}
        text={content.subtotalText}
        usedForCart={usedForCart}
      />

      {discountAmountFormatted && (
        <DiscountRow
          appliedText={content.discountAppliedText}
          amountFormatted={discountAmountFormatted}
          usedForCart={usedForCart}
        />
      )}

      <VatRateRow
        formattedTotalTaxAmount={totalTaxAmountFormatted}
        taxRate={orderSubscription.tax_rate}
        taxRateText={content.taxRateText}
        usedForCart={usedForCart}
        hideTaxPercentage={true}
      />

      <StyledHr />

      <TotalAmountRow
        amount={totalAmountFormatted}
        paymentStartLabel={dueMonthlyPaymentStartLabel}
        title={content.dueMonthlyTotalText}
        usedForCart={usedForCart}
      />
    </OverviewWrapper>
  );
};
