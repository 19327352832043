import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup-oss/circuit-ui';

export const ListItem = styled.li<{ usedForCart?: boolean }>(
  ({ theme, usedForCart }) => css`
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${usedForCart &&
    `
      background-color: var(--cui-bg-subtle);
      padding: ${theme.spacings.mega};
      border-radius: ${theme.spacings.mega};
    `}
  `,
);

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  display: flex;
  max-width: 64px;
  height: 64px;
  margin-right: var(--cui-spacings-kilo);
  background: var(--cui-bg-normal);
  border-radius: var(--cui-spacings-byte);
`;

export const CustomMarginText = styled(Body)`
  margin-bottom: var(--cui-spacings-byte);
`;

export const QuantityPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InstallmentContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledBolded = styled.b`
  font-weight: bold; // defaults to font-weight: inherit
`;
