import { Body } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

import * as TestIds from 'shared/constants/TestIds';
import type { AddressPayload } from 'shared/store/address/types';
import { getFormattedTaxAmount, getPercentageTaxRate } from 'utils/tax';

import { DescriptionContainer } from '../../OrderOverview.styles';

export type Props = {
  defaultTaxRate?: number;
  formattedTotalTaxAmount: string;
  locale?: string;
  shippingAddress?: AddressPayload;
  taxRate: number;
  taxRateText: string;
  totalAmountFloat?: number;
  usedForCart: boolean;
  hideTaxPercentage: boolean;
};

export const VatRateRow: FC<Props> = (props) => {
  const {
    defaultTaxRate = 0,
    formattedTotalTaxAmount,
    locale,
    shippingAddress,
    taxRate,
    taxRateText,
    totalAmountFloat = 0,
    usedForCart,
    hideTaxPercentage,
  } = props;
  // If there is an order tax rate calculated by CommerceLayer, use it
  if (taxRate > 0) {
    return (
      <DescriptionContainer
        data-testid={TestIds.OrderOverview.VAT}
        usedForCart={usedForCart}
      >
        <Body>{`${taxRateText}${!hideTaxPercentage ? ` (${getPercentageTaxRate(taxRate)}%)` : ' '}`}</Body>
        <Body>{formattedTotalTaxAmount}</Body>
      </DescriptionContainer>
    );
  }

  // If no address is attached, use the default tax rate
  // Background: CommerceLayer only calculates the correct taxes for an order once an address is attached to the order.
  // But we want to show the default tax rate before this calculation happens.
  if (!shippingAddress?.id && defaultTaxRate > 0) {
    const formattedTotalAmount = getFormattedTaxAmount(
      defaultTaxRate,
      totalAmountFloat,
      locale,
    );

    return (
      <DescriptionContainer
        data-testid={TestIds.OrderOverview.VAT}
        usedForCart={usedForCart}
      >
        <Body>{`${taxRateText} ${getPercentageTaxRate(defaultTaxRate)}%`}</Body>
        <Body>{formattedTotalAmount}</Body>
      </DescriptionContainer>
    );
  }

  return null;
};
